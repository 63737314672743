<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import AuthProvider from "@/views/pages/authentication/AuthProvider.vue";
import { VForm } from "vuetify/components/VForm";

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: true,
  },
});

// const posthog = inject("posthog");

const environment =
  import.meta.env.VITE_ENV === "development" ? "Development" : null;
const isPasswordVisible = ref(false);
const route = useRoute();
const router = useRouter();
const ability = useAbility();
const { signIn, updateSigninUserData, signin, googleCallback } = useAuthStore();

const googleAuthCode = ref(route.query.code);

const errors = ref({
  email: undefined,
  password: undefined,
});

const refVForm = ref();

const user = ref({
  // email: "suzaliikmal@gmail.com",
  // password: "Soccer12",
  // email: "test1234@gmail.com",
  // password: "Soccer12",
});

// const rememberMe = ref(false)

const onSubmit = async () => {
  const isValid = await refVForm.value?.validate();
  if (!isValid.valid) return;

  updateSigninUserData({
    email: user.value.email,
    password: user.value.password,
  });

  await signIn();

  await nextTick(() => {
    console.log("router", router);
    router.replace(route.query.to ? String(route.query.to) : "/");
  });
};

const init = async () => {
  try {
    if (googleAuthCode.value) {
      const googleCallbackResponse = await googleCallback(googleAuthCode.value);
      if (googleCallbackResponse.data.redirect === "signup") {
        router.push({
          name: "register",
          query: {
            user_email: googleCallbackResponse.data.email,
            name: googleCallbackResponse.data.name,
          },
        });
      } else {
        router.push("/");
      }
    } else {
      router.push("/");
    }
  } catch (error) {
    router.push("/");
  }
};

console.log("Created posthog", posthog); //posthog accessible anywhere!

init();
</script>

<template>
  <VRow no-gutters class="auth-wrapper bg-surface">
    <VCol
      cols="12"
      md="5"
      class="align-center justify-center"
      style="background-color: rgb(var(--v-theme-primary)); height: 100vh"
    >
      <VCard
        flat
        :elevation="2"
        class="d-flex align-center justify-center flex-column pa-4"
        style="height: auto; height: 100vh; border-radius: 0px"
      >
        <VCardText style="align-content: center; width: 80%">
          <div class="mb-4">
            <span>
              <!-- <VImg class="pb-4" max-width="200px" :src="atrLogo" /> -->
              <h2 class="text-h2 mb-1">{{ environment }}</h2>
            </span>

            <p class="mb-0">Please sign-in to your account</p>
          </div>
          <VForm ref="refVForm" @submit.prevent="onSubmit">
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="user.email"
                  :disabled="signin.isLoading"
                  label="Email"
                  placeholder="johndoe@email.com"
                  type="email"
                  autofocus
                  :rules="[requiredValidator, emailValidator]"
                  :error-messages="errors.email"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <AppTextField
                  v-model="user.password"
                  label="Password"
                  :disabled="signin.isLoading"
                  placeholder="············"
                  :rules="[requiredValidator]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :error-messages="errors.password"
                  :append-inner-icon="
                    isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />

                <div
                  class="d-flex align-center flex-wrap justify-space-between mt-1 mb-4"
                >
                  <RouterLink
                    class="text-primary ms-2 mb-1"
                    :to="{ name: 'forgot-password' }"
                  >
                    Forgot Password?
                  </RouterLink>
                </div>

                <VBtn :loading="signin.isLoading" block type="submit">
                  Login
                </VBtn>
              </VCol>

              <VCol cols="12" class="d-flex align-center">
                <VDivider />
                <span class="mx-4">or</span>
                <VDivider />
              </VCol>

              <VCol cols="12" class="text-center">
                <AuthProvider :is-google-btn-loading="!!googleAuthCode" />
              </VCol>
            </VRow>
          </VForm>

          <VCol cols="12" class="text-center mt-4">
            <span>New on our platform?</span>
            <RouterLink class="text-primary ms-2" :to="{ name: 'register' }">
              Create an account
            </RouterLink>
          </VCol>
        </VCardText>
      </VCard>
    </VCol>

    <VCol
      md="7"
      class="d-none d-md-flex"
      style="
        height: 100vh;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(var(--v-theme-primary));

        color: white;
        padding: 20px;
      "
    >
      <div style="text-align: left; max-width: 600px">
        <p style="font-size: 48px; margin-bottom: 20px; font-weight: 400">
          One click to link with Google Ads, Google Analytics, Facebook Ads,
          Instagram, LinkedIn
        </p>
        <p style="font-size: 18px; margin-bottom: 40px">
          Log in to manage your clients, streamline workflows, and access secure
          permissions—all from one dashboard.
        </p>
      </div>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
